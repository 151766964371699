import classNames from 'classnames';
import { CSSProperties } from 'react';

import styles from './index.module.scss';

const Pager = (props: { children: any; className?: string; style?: CSSProperties }) => {
	return (
		<div className={classNames(styles.pagerContainer, props?.className)} style={props?.style}>
			{props.children}
		</div>
	);
};

export default Pager;
