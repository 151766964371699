// import { useRouter } from 'next/router';

import { Button } from '$components/shared/button';
import { Form, FormItemInput, useForm } from '$components/shared/form';
import { toast } from '$components/shared/toast';
import { Header } from '$components/widget/header';
import { Pager } from '$components/widget/pager';
import { useBrowserNativeFunc } from '$lib/hooks/native/useBrowserNativeFunc';
import { useAppSelector } from '$lib/model/wrapper/common';
import { DEFAULT_LOGO_CLEARLY } from '$lib/utils/image_cdn';
import { useBack, useShare } from '$lib/utils/nativeFunc';
import { copy } from '$lib/utils/tools/copy';

const IndexPage = () => {
	const { query } = useAppSelector((state) => state.configModel);
	/** 调用原生下载图片方法 */
	const { run: downloadImage } = useBrowserNativeFunc<string>({
		funcName: 'downloadImage',
		hasCallback: false
	});
	const { run: share } = useShare();
	const { run: openMarket } = useBrowserNativeFunc({ funcName: 'openMarket' });
	const { run: openLink } = useBrowserNativeFunc({ funcName: 'openLink' });
	const [form] = useForm();
	const { run: back } = useBack();
	const headerStyle = { paddingTop: `${query?.top}px` };
	const bodyStyle = { paddingTop: `${query?.top + 68}px !important` };
	return (
		<Pager>
			<Header style={headerStyle} fixed title="hybrid-test" goBack={() => back()} />
			<div className="pa-24" style={bodyStyle}>
				<Button
					className="full-width mb-24"
					onClick={() => window.ReactNativeWebView.postMessage('custom-server')}
				>
					RN通信
				</Button>
				<Button
					className="full-width mb-24"
					onClick={() =>
						downloadImage(
							'https://staticscdn.zgzpsjz.com/new_yupao_hybrid/images/lqx/yp_store_header_bg.png'
						)
					}
				>
					保存图片
				</Button>
				<Button className="full-width mb-24" onClick={() => copy('复制成功！！！！！')}>
					复制
				</Button>
				<Button
					className="full-width mb-24"
					onClick={async () => {
						toast.show('分享');
						await share({
							path: `/subpackage/web-view/index?url=store/group-booking/detail/1607984986097508355&isLogin=true`,
							img: DEFAULT_LOGO_CLEARLY,
							title: `超划算，快来和我一起拼团购买`,
							desc: `超划算，快来和我一起拼团购买`
						});
					}}
				>
					分享
				</Button>
				<Button className="full-width mb-24" onClick={() => openMarket()}>
					应用市场(openMarket)
				</Button>
				<Form
					form={form}
					model={{ external: 'https://a.app.qq.com/o/simple.jsp?pkgname=io.dcloud.H576E6CC7' }}
				>
					<FormItemInput
						name="external"
						type="text"
						label="外部链接"
						placeholder="请输入外部链接"
						className="full-width mb-24"
					/>
				</Form>

				<Button
					className="full-width mb-24"
					onClick={() => {
						openLink({ url: form.getValue('external') });
					}}
				>
					跳转外部链接(openLink)
				</Button>
			</div>
		</Pager>
	);
};

export default IndexPage;
