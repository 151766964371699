import classNames from 'classnames';
// import { useRouter } from 'next/router';
import type { CSSProperties, ForwardedRef, ReactNode } from 'react';
import { forwardRef, memo } from 'react';

import TouchFeedBack from '$components/shared/touch-feed-back';
import { HEADER_BACK } from '$lib/utils/image_cdn';

import styles from './index.module.scss';

/** @name 顶部导航头参数 */
export interface HeaderProps {
	titleStyle?: CSSProperties;
	style?: CSSProperties;
	fixed?: boolean;
	/** 样式类名 */
	className?: string;
	/** 是否隐藏返回按钮-默认false */
	showBackBtn?: boolean;
	/** 标题是否靠左对齐-默认false居中 */
	leftTitle?: boolean;
	/** 标题 */
	title?: ReactNode | string;
	/** 右导航-节点 */
	rightNode?: ReactNode | string;
	/** 标题样式 */
	titleClassName?: string;
	/** 顶部返回箭头样式 */
	headerBackIconClass?: string;
	/** 点击返回按钮 */
	goBack?: () => void;
	/** 右导航点击 */
	onRightBtn?: () => void;
	/** 返回按钮icon样式 */
	backIconClass?: string;
	/** 返回按钮icon图标 */
	backIcon?: ReactNode;
	/** 标题图片展示 */
	titleIcon?: ReactNode;
	/** 返回按钮icon容器样式 */
	backIconWrapClass?: string;
	/** 导航栏顶部容器样式 */
	headerWrap?: string;
}

/** @name 顶部导航头 */
const Header = forwardRef((props: HeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
	const {
		className: _pClassName,
		showBackBtn: _pShowBackBtn,
		leftTitle: _pLeftTitle,
		title: _pTitle,
		rightNode: _pRightNode,
		titleClassName: _pTitleClass,
		headerBackIconClass: _pHeaderBackIconClass,
		fixed,
		style,
		onRightBtn,
		goBack,
		backIconClass: _pBackIconClass,
		backIcon,
		titleIcon,
		backIconWrapClass: _pBackIconWrapClass,
		headerWrap: _pHeaderWrap,
		titleStyle
	} = props;
	// const router = useRouter();
	const back = () => {
		goBack ? goBack?.() : history.back();
	};
	return (
		<div
			ref={ref}
			className={classNames(styles.container, _pClassName, { [styles.fixed]: fixed })}
			style={style}
		>
			<div className={classNames(styles.header, _pHeaderWrap)}>
				{!!_pShowBackBtn && (
					<TouchFeedBack activeClassName="touch-feed-back">
						<div
							className={classNames('white-bg', styles['header-back-icon'], _pBackIconWrapClass)}
							onClick={back}
						>
							{backIcon ? (
								backIcon
							) : (
								<img
									alt="返回"
									src={HEADER_BACK}
									className={classNames(styles['icon'], _pBackIconClass)}
								/>
							)}
						</div>
					</TouchFeedBack>
				)}
				<div
					style={titleStyle}
					className={classNames(
						styles['header-title'],
						{ [styles['header-title-left']]: _pLeftTitle },
						_pTitleClass
					)}
				>
					{titleIcon ? titleIcon : _pTitle}
				</div>
				<span
					className={classNames(styles['header-right'], _pHeaderBackIconClass)}
					onClick={onRightBtn}
				>
					{_pRightNode}
				</span>
			</div>
		</div>
	);
});
Header.defaultProps = {
	showBackBtn: true
};

export default memo(Header);
