import { toast } from '$components/shared/toast';

/** 复制 */
export const copy = (text: string) => {
	const input = document.createElement('input');
	document.body.appendChild(input);
	input.setAttribute('readonly', 'readonly');
	input.setAttribute('value', text);
	input.select();
	input.setSelectionRange(0, text.length);
	try {
		document.execCommand('copy');
		toast.show('复制成功');
	} catch (err) {}
	document.body.removeChild(input);
};
